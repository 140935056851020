import React, { FunctionComponent } from 'react';
import { isNil } from 'ramda';

interface TextOverlayProps {
  children?: React.ReactNode;
  text: string;
}

const TextOverlay: FunctionComponent<TextOverlayProps> = ({ children, text }) => {
  if (isNil(children)) {
    return null;
  }
  return (
    <div className="apl-flex-row apl-display-flex apl-align-items-center">
      {children}
      <span
        className="apl-text-muted"
        style={{
          fontSize: '14px',
          marginLeft: '-45px',
          marginTop: '-2px',
        }}
      >
        {text}
      </span>
    </div>
  );
};

export default TextOverlay;

import React from 'react';
import { Link } from 'react-router-dom';
import { subSeconds, format } from 'date-fns';
import { isEmpty } from 'ramda';
import {
  SupplyPeriodSummary,
  useSupplyPeriodSummaryBetween,
} from '../../connections/connectionsApi';
import { newMarketDate } from '../../../util/helper-func';

const FailedRatingRequestLine = (props: any) => {
  const { connectionId, isAvailable, item, index } = props;

  const id = item.context.scopedTo.split(':').pop();
  const from = item.context.startsAt
    ? format(newMarketDate(item.context.startsAt), 'yyyy-MM-dd')
    : '';
  const to = item.context.endsAt
    ? format(subSeconds(newMarketDate(item.context.endsAt), 1), 'yyyy-MM-dd')
    : '';

  let linkUrl = `/connections/${id}`;

  // do NOT request the SupplyPeriodSummary if the connection is not available in Rating-Config
  if (isAvailable) {
    const {
      data,
      isError,
      isInitialLoading: isLoading,
    } = useSupplyPeriodSummaryBetween(id, from, to);

    if (isLoading) {
      return (
        <tr>
          <td colSpan={4}>Loading...</td>
        </tr>
      );
    }

    if (!isError) {
      if (data?.supplyPeriodSummaries?.length === 1 && data.supplyPeriodSummaries[0]?.owner) {
        const owner = data.supplyPeriodSummaries[0].owner;
        if (!isEmpty(owner)) {
          linkUrl += `/contracted-parties/${owner}`;
        }
      }
      // more than one supplyPeriods, filter valid only
      if (data?.supplyPeriodSummaries?.length > 1) {
        const matchingValidSupplyPeriodSummaries = (
          data.supplyPeriodSummaries as SupplyPeriodSummary[]
        ).filter((s) => s.status === 'VALID');
        if (
          matchingValidSupplyPeriodSummaries?.length === 1 &&
          matchingValidSupplyPeriodSummaries[0]?.owner
        ) {
          const owner = matchingValidSupplyPeriodSummaries[0].owner;
          linkUrl += `/contracted-parties/${owner}`;
        }
      }
    }
  }

  const { errors } = item;
  let error;

  if (errors && errors.length) {
    try {
      error = errors.join(', ');
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <tr key={`failed-rating-request-row-${index}`}>
      <td>
        {isAvailable ? (
          <Link className="apl-color-primary" to={linkUrl}>
            {connectionId}
          </Link>
        ) : (
          <>{connectionId}</>
        )}
      </td>
      <td>{error}</td>
      <td>
        {format(newMarketDate(item.context.startsAt), 'd MMM yyyy') + ' - '}
        {format(subSeconds(newMarketDate(item.context.endsAt), 1), 'd MMM yyyy')}
      </td>
      <td>{format(newMarketDate(item.context.requestedAt), 'dd-MM-yyyy HH:mm:ss')}</td>
      <td></td>
    </tr>
  );
};

export default FailedRatingRequestLine;

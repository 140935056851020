import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Alert, { AlertEnum } from '../layout/Alert';
import { useAppUpdate } from './AppUpdateContext';
import { EuiButton } from '@elastic/eui';

export const AppUpdateChecker = () => {
  const { isUpdateAvailable, updateAssets } = useAppUpdate();
  const [lastUpdateCheck, setLastUpdateCheck] = useState<number>(Date.now());
  const location = useLocation();

  useEffect(() => {
    const FIVE_MINUTES = 5 * 60 * 1000;
    const fiveMinutesAgo = Date.now() - FIVE_MINUTES;
    const secondsUntilCheck = Math.round((lastUpdateCheck - fiveMinutesAgo) / 1000);
    console.debug('Checking in: ' + secondsUntilCheck + ' seconds');

    if (lastUpdateCheck < fiveMinutesAgo) {
      console.debug('Checking now: ' + secondsUntilCheck);
      setLastUpdateCheck(Date.now());
      navigator.serviceWorker.ready.then((registration) => {
        registration.update().then(() => {
          console.debug('update complete');
        });
      });
    }
  }, [location, lastUpdateCheck]);

  return (
    <>
      {isUpdateAvailable && (
        <Alert type={AlertEnum.WARNING}>
          <EuiButton size="s" onClick={updateAssets}>
            A new version of the application is available. Please save your work and then click here
            to update.
          </EuiButton>
        </Alert>
      )}
    </>
  );
};

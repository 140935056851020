import { useMutation, useQuery } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';
import { handleRequest, ratingCalculatorClient } from '../../api';

const baseEndpoint = '/ratings';

export interface OfferComparisons {
  connectionId: string;
  startsAt: string;
  endsAt: string;
  requestedAt: string;
  isCurrentBest: boolean;
  current: Plan;
  best: Plan;
  savings: UnitValue;
  planName: string;
}

interface Plan {
  planName: string;
  totalPrice: UnitValue;
}

export const useRatingCalculatorMetrics = (connectionId?: string) => {
  return useQuery(
    ['metrics'],
    async () =>
      await handleRequest(
        ratingCalculatorClient.ratingCalculatorMetricsService.metrics(connectionId)
      )
  );
};

interface UnitValue {
  value: number;
  unit: string;
}
export interface RatingCalculationPageResponse {
  _itemType: string;
  totalCount: number;
  items: RatingCalculation[];
  _links: Links;
}

export interface RatingCalculation {
  id: string;
  context: RatingCalculationContext;
  status: RatingStatus;
  errors: string[];
}

interface RatingCalculationContext {
  scopedTo: string;
  startsAt: string;
  endsAt: string;
  requestedAt: string;
  purpose: string;
}

interface Links {
  self: string;
  next: string;
  prev: string;
}

export type RatingStatus =
  | 'urn:flux:rating:request:status:in-progress'
  | 'urn:flux:rating:request:status:in-progress:delayed'
  | 'urn:flux:rating:request:status:completed'
  | 'urn:flux:rating:request:status:failed';

export interface RatingsQueryParams {
  connectionId?: string;
  requestedAtTo: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  requestedAtFrom: string;
  status: RatingStatus[];
  notRequestedBy?: string[];
  omitFailedWithSubsequentSuccess?: boolean;
  omitInProgressWithSubsequentSuccess?: boolean;
  omitInProgressReceivedLessThanSecondsAgo?: number;
  purpose?: string;
}

export const useRatings = (params: RatingsQueryParams) => {
  const {
    connectionId,
    requestedAtTo,
    limit,
    offset,
    sortBy,
    requestedAtFrom,
    status,
    notRequestedBy,
    omitFailedWithSubsequentSuccess,
    omitInProgressWithSubsequentSuccess,
    omitInProgressReceivedLessThanSecondsAgo,
    purpose,
  } = params;

  return useQuery(
    [
      'ratings',
      connectionId,
      requestedAtTo,
      limit,
      offset,
      sortBy,
      requestedAtFrom,
      status,
      notRequestedBy,
      omitFailedWithSubsequentSuccess,
      omitInProgressWithSubsequentSuccess,
      omitInProgressReceivedLessThanSecondsAgo,
      purpose,
    ],
    async () => {
      const search = new URLSearchParams();

      (Object.keys(params) as Array<keyof RatingsQueryParams>).forEach((key) => {
        if (params[key]) {
          search.set(key, `${params[key]}`);
        }
      });

      return apiClient('rating-calculator').get(`${baseEndpoint}?${search.toString()}`);
    }
  );
};

interface TriggerRatingProps {
  scopedTo: string | undefined;
  startAt: string;
  endAt: string;
  purpose: string;
}

export const useTriggerRating = () => {
  // we need to set the endAt to the following day at 00:00:00 for the billing api/gorilla
  return useMutation(({ scopedTo, startAt, endAt, purpose }: TriggerRatingProps) => {
    return apiClient('rating-calculator').post(
      baseEndpoint,
      JSON.stringify({
        scopedTo: `urn:flux:rating:request:scope:connection:${scopedTo}`,
        startAt,
        endAt,
        requestedBy: 'aib_ui',
        purpose: purpose,
      })
    );
  });
};

export function useOfferComparisons(
  connectionId: string,
  startsAt: string | undefined,
  endsAt: string | undefined,
  latest: boolean
) {
  let path = `/offer-comparisons?connectionId=${connectionId}&latest=${latest}`;
  if (startsAt) path = path + '&startsAt=' + encodeURIComponent(startsAt);
  if (endsAt) path = path + '&endsAt=' + encodeURIComponent(endsAt);
  return useQuery(['supply-period-details'], async () => {
    return apiClient('rating-calculator').get(path);
  });
}

interface BestOfferReportsProps {
  requestedFrom: string;
  requestedTo: string;
}

export function useReports() {
  return useMutation(({ requestedFrom, requestedTo }: BestOfferReportsProps) => {
    return apiClient('rating-calculator').post(
      `/reports`,
      JSON.stringify({
        from: requestedFrom,
        to: requestedTo,
        outputFormat: 'text/csv',
      })
    );
  });
}

import React, { useEffect, useState } from 'react';
import {
  compareDesc,
  differenceInCalendarDays,
  format,
  getDaysInMonth,
  startOfMonth,
} from 'date-fns';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import MeteringConsumptionSummary from './MeteringConsumptionSummary';
import { SupplyPeriodSummary, useSupplyPeriodSummary } from '../connectionsApi';
import EmptyTableRow from '../../common/EmptyTableRow';
import { findMeasurement, FlowDirection } from '../dashboard/MeteringDataComponent';
import Card from '../../layout/Card';
import Page, { PageHeader } from '../../layout/Page';
import { useMeasurementPoints, useUsageSummaryV2 } from '../../telemetry/telemetryUsageApi';
import {
  capitaliseFirstText,
  FieldOption,
  generateDateOptions,
  generateDateOptionsBaseFromSupplyPeriod,
  newMarketDate,
  newUTCDate,
  startOfDayFormatted,
} from '../../../util/helper-func';
import caretRight from '../../../icons/caret-right.svg';
import { useExternalBillingPresentation } from 'components/core/coreExternalBillingApi';
import { AggregatedSummary, Measurement, UsageSummaryV2Item } from '../../../api/openapi/telemetry';
import { buildScopedToQueryField } from '../measurement-points/measurementPointHelpers';
import { getMeasurementPointFlow, getMeasurementPointOptions } from './MeteringSummaryHelper';
import getConfig from 'config/getConfig';
import { addDaysInZone, addMonthsInZone, startOfDayInZone } from '@developers/flux-date-util';

const dateFormat = 'yyyy-MM-dd';

const MeteringMonthlySummary = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const queryDate = searchParams.get('date');
  const initialDate = queryDate ? newUTCDate(queryDate) : newUTCDate();
  const initialMeasurementPointType: string = searchParams.get('measurementPointType') || 'Meter';
  const initialMeasurementPointId: string | undefined =
    searchParams.get('measurementPointId') || undefined;
  const initialFlow: FlowDirection = (searchParams.get('flow') as FlowDirection) || 'IN';
  const [from, setFrom] = useState<string>(format(startOfMonth(initialDate), 'yyyy-MM-dd'));
  const [measurementPointType, setMeasurementPointType] = useState<string>(
    initialMeasurementPointType
  );
  const [measurementPointId, setMeasurementPointId] = useState<string | undefined>(
    initialMeasurementPointId
  );
  const [flow] = useState<FlowDirection>(initialFlow);
  const [startsAt, setStartsAt] = useState<Date>();
  const [endsAt, setEndsAt] = useState<Date>();
  const [fromOptions, setFromOptions] = useState<FieldOption[]>([]);
  const { id } = useParams<{ id: string }>();

  const {
    data: supplyPeriodData,
    isError: supplyPeriodDataError,
    isInitialLoading: supplyPeriodDataLoading,
  } = useSupplyPeriodSummary(id, { enabled: !!id });

  const scopedToQueryField = buildScopedToQueryField(id ?? '');
  const {
    data: measurementPointData,
    isError: measurementPointDataError,
    isInitialLoading: measurementPointDataLoading,
  } = useMeasurementPoints(
    {
      scopedTo: scopedToQueryField,
      startsAt: startOfDayFormatted(startsAt?.toISOString() ?? ''),
      endsAt: startOfDayFormatted(endsAt?.toISOString() ?? ''),
      limit: 100,
      offset: 0,
    },
    {
      enabled: !!startsAt && !!endsAt,
    }
  );

  const {
    data,
    isError,
    isInitialLoading: isLoading,
  } = useUsageSummaryV2(
    {
      connectionId: id,
      measurementPointId: measurementPointId,
      startsAt: startOfDayFormatted(startsAt?.toISOString() ?? ''),
      endsAt: startOfDayFormatted(endsAt?.toISOString() ?? ''),
      limit: 31,
    },
    {
      enabled: !!id && !!measurementPointId && !!startsAt && !!endsAt,
    }
  );

  const supplyPeriodId = searchParams && searchParams.get('supply_period');
  const supplyPeriod: SupplyPeriodSummary =
    !supplyPeriodDataLoading &&
    supplyPeriodData &&
    supplyPeriodId &&
    supplyPeriodData?.supplyPeriodSummaries?.find(
      (period: SupplyPeriodSummary) => period.id === supplyPeriodId
    );

  useEffect(() => {
    // update the URL whenever measurement point type, measurement point or date changes
    const params = new URLSearchParams();

    measurementPointType && params.set('measurementPointType', measurementPointType);
    measurementPointId && params.set('measurementPointId', measurementPointId);
    params.set('date', from);
    params.set('flow', flow);
    params.set('supply_period', supplyPeriodId ? supplyPeriodId : '');

    navigate({ search: params.toString() }, { replace: true });
  }, [measurementPointType, measurementPointId, from, flow]);

  useEffect(() => {
    // set the date options base from the supply period
    if (supplyPeriod) {
      setFromOptions(generateDateOptionsBaseFromSupplyPeriod(supplyPeriod));
    } else {
      setFromOptions(generateDateOptions(true, 24, 'MMMM yyyy', 'yyyy-MM-dd'));
    }
  }, [supplyPeriod]);

  // adjust the metering data date range base from the supply period
  useEffect(() => {
    if (from && supplyPeriod) {
      const config = getConfig();

      const monthStart = newMarketDate(from);
      const supplyPeriodStartDate = newMarketDate(supplyPeriod.startDate);
      setStartsAt(monthStart > supplyPeriodStartDate ? monthStart : supplyPeriodStartDate);

      const monthEnd = addMonthsInZone(monthStart, 1, config.marketTimezone);
      if (supplyPeriod.endDate) {
        const supplyPeriodEndDate = startOfDayInZone(
          addDaysInZone(new Date(supplyPeriod.endDate), 1, config.marketTimezone),
          config.marketTimezone
        );
        setEndsAt(monthEnd < supplyPeriodEndDate ? monthEnd : supplyPeriodEndDate);
      } else {
        setEndsAt(monthEnd);
      }
    }
  }, [from, supplyPeriod]);

  const daysInMonth =
    startsAt && endsAt
      ? differenceInCalendarDays(endsAt, startsAt)
      : getDaysInMonth(newUTCDate(from));

  let measurementPointOptions = getMeasurementPointOptions(
    measurementPointType,
    measurementPointData
  );

  useEffect(() => {
    //only after the measurement points data have been populated
    if (measurementPointData) {
      //reload the select box every time the measurementPointType changes
      measurementPointOptions = getMeasurementPointOptions(
        measurementPointType,
        measurementPointData
      );
      //auto-select the first
      setMeasurementPointId(measurementPointOptions?.[0]?.value);
    }
  }, [measurementPointType]);

  const totals = measurementPointData?.items?.find(
    (measurementPoint) => measurementPoint.id === measurementPointId
  )?.isEnergyGoingToConnection
    ? data?.totalsToConnection?.totalValues
    : data?.totalsFromConnection?.totalValues;

  const connectionId = supplyPeriodData?.connectionId ?? id;
  const completeSummaries = data?.numberOfCompleteSummaries ?? 0;

  // pull the contracted party name from core external billing
  const periodAgreementId = supplyPeriod?.externalSupplyAgreementId;
  const { data: externalData } = useExternalBillingPresentation(periodAgreementId ?? '', {
    enabled: !!periodAgreementId,
  });

  const contractedParty =
    externalData && externalData?.parties?.owner?.name ? externalData.parties.owner.name : '-';
  const contractedPartyId =
    externalData && externalData?.parties?.owner?.id ? externalData.parties.owner.id : undefined;

  return (
    <>
      <PageHeader
        title={() => (
          <>
            <Link
              className="page__title-link"
              to={`/connections/${id}/supply-periods/${supplyPeriodId}/contracted-parties/${contractedPartyId}`}
            >
              {connectionId}
            </Link>
            <img src={caretRight} alt="" />
            Monthly metering data
          </>
        )}
      />
      <Page>
        <Card>
          <div className="apl-display-flex apl-flex-row">
            <div className="apl-field-v1 apl-display-flex apl-flex-column apl-align-items-start">
              <label className="apl-field__label apl-mr" htmlFor="contracted-party-field">
                Contracted party
              </label>
              <p className="apl-my-none apl-h3" id="contracted-party-field">
                {contractedParty}
              </p>
            </div>
          </div>
        </Card>
        <Card>
          <div className="card__inner apl-display-flex apl-flex-row apl-align-items-center apl-justify-content-between apl-px">
            <div className="apl-display-flex apl-flex-row apl-align-items-center">
              <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr-s">
                <label className="apl-field__label apl-mr-s apl-mb-none" htmlFor="type-field">
                  Type
                </label>
                <select
                  className="apl-select-v1_0 apl-mb-none"
                  data-testid="type field"
                  id="type-field"
                  name="meterType"
                  onChange={(e) => setMeasurementPointType(e.target.value)}
                  value={measurementPointType}
                >
                  <option value="Meter">Meter</option>
                  <option value="Calculated">Calculated</option>
                </select>
              </div>
              <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr-s">
                <label className="apl-field__label apl-mr-s apl-mb-none" htmlFor="meter-field">
                  Measurement Point
                </label>
                <select
                  className="apl-select-v1_0 apl-mb-none"
                  data-testid="meter field"
                  id="meter-field"
                  name="meter"
                  onChange={(e) => setMeasurementPointId(e.target.value)}
                  value={measurementPointId}
                >
                  {measurementPointOptions.map((option: Record<string, string>) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr-s">
                <label className="apl-field__label apl-mr-s apl-mb-none" htmlFor="date-field">
                  Month
                </label>
                <br />
                <select
                  className="apl-select-v1_0 apl-mb-none"
                  data-testid="from field"
                  id="from-field"
                  name="from"
                  onChange={(e) => setFrom(e.target.value)}
                  value={from}
                >
                  {fromOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <p className="apl-my-none apl-mr-s">
                <strong>Flow:</strong>
                {`${capitaliseFirstText(
                  getMeasurementPointFlow(measurementPointId, measurementPointData, flow)
                )}`}
              </p>
              <p className="apl-my-none apl-mr-s">
                <strong>Complete days:</strong> {`${completeSummaries}/${daysInMonth}`}
              </p>
              <p className="apl-my-none">
                <strong>Incomplete days:</strong> {daysInMonth - completeSummaries}
              </p>
            </div>
          </div>
        </Card>
        <MeteringConsumptionSummary
          flow={getMeasurementPointFlow(measurementPointId, measurementPointData, flow)}
          measurements={totals}
          title="Monthly total consumption"
        />
        <Card className="card--square-top apl-pb-none">
          <table className="apl-table-v1 apl-mb-none">
            <thead>
              <tr>
                <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                  Date
                </th>
                <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                  Intervals
                </th>
                <th className="apl-text-right" colSpan={3}>
                  Raw consumption
                </th>

                <th className="apl-text-right" colSpan={3}>
                  Distribution loss adjusted consumption
                </th>
                <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                  Usage Charge Filter
                </th>
              </tr>

              <tr>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kWh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kVAh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kVArh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kWh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kVAh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kVArh
                </th>
              </tr>
            </thead>
            {(isLoading || supplyPeriodDataLoading || measurementPointDataLoading) && (
              <tbody>
                <tr>
                  <td>Loading...</td>
                </tr>
              </tbody>
            )}
            {(isError || supplyPeriodDataError || measurementPointDataError) && (
              <tbody>
                <tr>
                  <td>Sorry, there was an error.</td>
                </tr>
              </tbody>
            )}
            {data && data?.items?.flatMap((i: UsageSummaryV2Item) => i.summaries)?.length > 0 && (
              <tbody data-testid="usage-summary-rows">
                {data?.items.map((item: UsageSummaryV2Item) => {
                  return item.summaries
                    ?.sort((summaryA: AggregatedSummary, summaryB: AggregatedSummary) =>
                      compareDesc(
                        newMarketDate(summaryA.periodStart),
                        newMarketDate(summaryB.periodStart)
                      )
                    )
                    .map((summary: AggregatedSummary) => {
                      const { periodStart, measurements } = summary;

                      // Convert from UTC to Local (Market Time Zone) date display
                      const summaryPeriod: string = periodStart
                        ? format(newMarketDate(periodStart), dateFormat)
                        : '';

                      const summaryValues: Array<Measurement> = measurements
                        ? measurements
                        : new Array<Measurement>();
                      return (
                        <tr key={`row-${summaryPeriod}`}>
                          <td className="apl-text-left">
                            <Link
                              className="apl-color-primary"
                              to={`/connections/${id}/metering/day?measurementPointType=${measurementPointType}&measurementPointId=${measurementPointId}&date=${summaryPeriod}&supply_period=${supplyPeriod.id}`}
                            >
                              {format(newMarketDate(summaryPeriod), 'dd MMM yyyy')}
                            </Link>
                          </td>
                          <td className="apl-text-left">{summary.numberOfReadingsContributed}</td>
                          <td className="apl-text-right">
                            {findMeasurement('kWh', summaryValues)} kWh
                          </td>
                          <td className="apl-text-right">
                            {findMeasurement('kVAh', summaryValues)} kVAh
                          </td>
                          <td className="apl-text-right">
                            {findMeasurement('kVArh', summaryValues)} kVArh
                          </td>
                          <td className="apl-text-right">
                            {findMeasurement('loss_adjusted_kWh', summaryValues)} kWh
                          </td>
                          <td className="apl-text-right">
                            {findMeasurement('loss_adjusted_kVAh', summaryValues)} kVAh
                          </td>
                          <td className="apl-text-right">
                            {findMeasurement('loss_adjusted_kVArh', summaryValues)} kVArh
                          </td>
                          <td className="apl-text-left">
                            {item.measurementPoint?.usageChargeFilter as string}
                          </td>
                        </tr>
                      );
                    });
                })}
              </tbody>
            )}
            {data && data?.items?.flatMap((i: UsageSummaryV2Item) => i.summaries)?.length === 0 && (
              <EmptyTableRow message="Sorry, there are no summaries for this month." />
            )}
          </table>
        </Card>
      </Page>
    </>
  );
};

export default MeteringMonthlySummary;

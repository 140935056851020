import React, { FunctionComponent, useState } from 'react';
import cx from 'classnames';
import { useCurrencyUnitTranslation, useMassUnitTranslation } from 'hooks';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { Permission } from '../../../auth/getPermissions';
import { capitaliseFirstText, formatDateForDisplay, sortByDate } from '../../../util/helper-func';
import AuthorizedAction from '../../common/AuthorizedAction';
import ColumnCard, { LabelValue } from '../../layout/ColumnCard';
import { chargeModalStyles } from '../../layout/Modal';
import { useDeleteChargeV2 } from '../planApi';
import EditSimpleCharge from './EditSimpleCharge';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  chargeRequiresApplicableTimePeriod,
  chargeRequiresFlowDirection,
  chargeRequiresIntervalSize,
  chargeRequiresLookbackMonths,
  chargeRequiresLossAdjustment,
  chargeRequiresMinimumChargeable,
  chargeRequiresHistoricalMeasure,
  chargeRequiresPowerFactorThreshold,
  chargeRequiresSpotPercentage,
  chargeRequiresSummarisationFunction,
  chargeRequiresTopNPeriods,
  chargeRequiresVolumeAdjustmentFactor,
  chargeRequiresUsageChargeFilter,
  chargeRequiresMinimumThresholdMethod,
  chargeRequiresReadingQuality,
  chargeRequiresSteppedTariff,
  chargeRequiresIsTaxed,
} from 'list-data/charge-types/chargeTypesConfigUtils';
import { useListData } from 'list-data/ListDataContext';
import { ChargeTypesConfig } from 'list-data/charge-types/chargeTypesConfigUtils';
import {
  getSumFunctionLabelByValue,
  SummarisationFunctionOptions,
} from 'list-data/summarisation-function/summarisationFunctionUtils';
import { getFlagValue } from 'util/launchdarkly.util';
import { Charge, Plan, Provider } from '../../../api/openapi/rating-config';
import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import SteppedTariffChargeDetails from './SteppedTariffChargeDetails';
import { formatRateUnit } from './simpleChargeUtils';
import { toString, useDiscountVariations } from '../DiscountVariationsApi';
import { DiscountVariations } from '../../../api/openapi/billing-lifecycle-api/models/DiscountVariations';
import getConfig from '../../../config/getConfig';

interface ChargeDetailsProps {
  charge: Charge;
  index: number;
  plan: Plan;
  planStatus?: string;
  provider: Provider;
}

interface LabelData {
  label: string;
  value: string[];
}

interface HistoricalMeasureData {
  label: string;
  value: string;
}

const ChargeDetails: FunctionComponent<ChargeDetailsProps> = ({
  charge,
  index,
  plan,
  planStatus,
  provider,
}) => {
  const {
    fbau721ExpandVolumeChargeToIncludeNewAttributesAndChargeBasis, // LD Client Key is fbau-721-expand-volume-charge-to-include-new-attributes-and-charge-basis: true
    fbau689SpotPercentageOnVolumeEnergyCharge, // LD Client Key is fbau-689-spot-percentage-on-volume-energy-charge
    fbau1079AddUsageChargeFilterToVolumeBasedCharges, // LD Client Key is // fbau-1079-add-usage-charge-filter-to-volume-based-charges
    nz1683AddCostToUi, // LD Client Key is nz-1683-add-cost-to-ui
    cfd872SteppedTariffs,
  } = useFlags();
  const flags = {
    fbau1079AddUsageChargeFilterToVolumeBasedCharges,
  };

  const [chargeTypesList, summarisationFunctionList, lossFactorLabels, historicalMeasureList] =
    useListData([
      'CHARGE_TYPES',
      'SUMMARISATION_FUNCTION',
      'LOSS_FACTOR_LABELS',
      'HISTORICAL_MEASURE',
    ]);
  const chargeTypesConfig = chargeTypesList?.data as ChargeTypesConfig;
  const sumFunctionOptions = summarisationFunctionList?.data as SummarisationFunctionOptions;
  const historicalMeasureOptions = historicalMeasureList?.data as HistoricalMeasureData[];
  const labels: LabelData[] = lossFactorLabels?.data as LabelData[];

  const arrayCompare = (array1: any[], array2: any[]) => {
    if (Array.isArray(array1) && Array.isArray(array2)) {
      const array2Sorted = array2.slice().sort();
      return (
        array1.length === array2.length &&
        array1
          .slice()
          .sort()
          .every(function (value, index) {
            return value === array2Sorted[index];
          })
      );
    }

    return false;
  };

  const getLossesText = (charge: Charge): string => {
    const matching = labels.find((label) => {
      return arrayCompare(label.value, charge?.adjustmentToUse as any[]);
    });
    return matching?.label || '';
  };

  const [editChargeIsOpen, setEditChargeIsOpen] = useState(false);
  const closeEditCharge = () => setEditChargeIsOpen(false);
  const openEditCharge = () => setEditChargeIsOpen(true);

  const [sortAsc, setSortAsc] = useState(false);
  const [sortCol, setSortCol] = useState('from');

  const currencyUnit = useCurrencyUnitTranslation();
  const tonneUnit = useMassUnitTranslation('T');

  const { mutate } = useDeleteChargeV2();

  const chargeName = charge.chargeName.replace(/ /g, '-').toLowerCase();
  const hasApplicableTimePeriod = chargeRequiresApplicableTimePeriod(
    charge.chargeType,
    chargeTypesConfig
  );

  const testIdBase = `simple-charge-${index}-display`;

  function getHistoricalMeasureText(historicalMeasure: string | undefined) {
    const obj = historicalMeasureOptions.find((label) => label.value === historicalMeasure);
    return obj != null ? obj.label : '';
  }

  return (
    <div className="apl-mb-l">
      <div
        className={cx(
          'apl-display-flex',
          'apl-flex-row',
          'apl-justify-content-between',
          'apl-align-items-center'
        )}
      >
        <h3
          className="apl-mb-none"
          data-testid={`${testIdBase}-charge-type`}
          style={{
            fontSize: '17px',
          }}
        >
          {charge?.chargeType.substr(0, 1) +
            charge.chargeType.substring(1).replace(/_/g, ' ').toLowerCase()}
        </h3>
        <div>
          {plan.planState !== 'ARCHIVED' && (
            <EuiFlexGroup gutterSize="s" alignItems="center">
              <EuiFlexItem grow={false}>
                <AuthorizedAction
                  extraClasses="is-tiny"
                  isDisabled={planStatus !== 'DRAFT'}
                  title="Sorry, this is not allowed."
                  onClick={() => {
                    mutate({
                      planId: plan?.id ? plan.id : '',
                      chargeId: charge?.id ? charge.id : '',
                    });
                  }}
                  permission={Permission.PLAN_EDIT}
                  testId={`${chargeName}-delete-button`}
                >
                  Delete
                </AuthorizedAction>
              </EuiFlexItem>

              <EuiFlexItem grow={false}>
                <AuthorizedAction
                  extraClasses="is-tiny"
                  isDisabled={planStatus === 'DEPRECATED'}
                  title="Sorry, this is not allowed."
                  onClick={openEditCharge}
                  permission={Permission.PLAN_EDIT}
                  testId={`${chargeName}-edit-button`}
                >
                  Edit charge
                </AuthorizedAction>
              </EuiFlexItem>
            </EuiFlexGroup>
          )}
        </div>
      </div>
      <ColumnCard
        content={processChargeDetails(
          charge,
          chargeTypesConfig,
          sumFunctionOptions,
          getLossesText,
          getHistoricalMeasureText,
          flags
        )}
        id={testIdBase}
        buttonColumnVisible={false}
      >
        {cfd872SteppedTariffs &&
        chargeRequiresSteppedTariff(charge?.chargeType, chargeTypesConfig) ? (
          <SteppedTariffChargeDetails charge={charge as Charge} />
        ) : (
          <table className="apl-table-v1 apl-width-full" data-testid={`charge-table-${index}`}>
            <thead>
              <tr>
                <th
                  className={cx({
                    'is-sortable': sortCol === 'rate',
                    'is-sorted-desc': sortCol === 'rate' && !sortAsc,
                    'is-sorted-asc': sortCol === 'rate' && sortAsc,
                  })}
                  onClick={() => {
                    setSortCol('rate');
                    setSortAsc(!sortAsc);
                  }}
                >
                  Rate
                </th>
                {nz1683AddCostToUi && (
                  <th
                    className={cx({
                      'is-sortable': sortCol === 'cost',
                      'is-sorted-desc': sortCol === 'cost' && !sortAsc,
                      'is-sorted-asc': sortCol === 'cost' && sortAsc,
                    })}
                  >
                    Cost
                  </th>
                )}
                <th
                  className={cx({
                    'is-sortable': sortCol === 'from',
                    'is-sorted-desc': sortCol === 'from' && !sortAsc,
                    'is-sorted-asc': sortCol === 'from' && sortAsc,
                  })}
                  onClick={() => {
                    setSortCol('from');
                    setSortAsc(!sortAsc);
                  }}
                >
                  Valid from
                </th>
                <th
                  className={cx({
                    'is-sortable': sortCol === 'to',
                    'is-sorted-desc': sortCol === 'to' && !sortAsc,
                    'is-sorted-asc': sortCol === 'to' && sortAsc,
                  })}
                  onClick={() => {
                    setSortCol('to');
                    setSortAsc(!sortAsc);
                  }}
                >
                  Valid to
                </th>
                <th>Rate Qualifiers</th>
                {hasApplicableTimePeriod && <th>Applicable Time Periods</th>}
                <th>Bill group</th>
                {fbau721ExpandVolumeChargeToIncludeNewAttributesAndChargeBasis &&
                  chargeRequiresVolumeAdjustmentFactor(charge.chargeType, chargeTypesConfig) && (
                    <th>Volume Adjustment Factor</th>
                  )}
                {fbau689SpotPercentageOnVolumeEnergyCharge &&
                  chargeRequiresSpotPercentage(
                    charge.chargeType,
                    charge.chargeTags?.[0],
                    chargeTypesConfig
                  ) && <th>Spot Percentage</th>}
              </tr>
            </thead>
            <tbody>
              {charge && charge?.schedules
                ? charge.schedules
                    .sort((a, b) => {
                      switch (sortCol) {
                        case 'rate': {
                          const defaultRate = sortAsc ? Infinity : -Infinity;

                          const rateA = a.rates && a.rates[0] ? a.rates[0].rate : defaultRate;
                          const rateB = b.rates && b.rates[0] ? b.rates[0].rate : defaultRate;

                          if (sortAsc) {
                            return rateA - rateB;
                          }
                          return rateB - rateA;
                        }
                        case 'to':
                          return sortByDate(a?.validTo, b?.validTo, sortAsc);

                        case 'from':
                        default:
                          return sortByDate(a?.validFrom, b?.validFrom, sortAsc);
                      }
                    })
                    .map((s, index) => {
                      return (
                        <tr key={index}>
                          <td data-testid={`${testIdBase}-rate-${index + 1}`}>
                            {s.rates?.[0].rate}
                            {' ' +
                              formatRateUnit({
                                chargeBasis: charge?.chargeBasis,
                                rate: s.rates?.[0].rate ?? 0,
                                flags: {
                                  fbau721ExpandVolumeChargeToIncludeNewAttributesAndChargeBasis,
                                },
                                tonneUnit: tonneUnit,
                                currencyUnit: currencyUnit,
                              })}
                          </td>
                          {nz1683AddCostToUi && (
                            <td data-testid={`${testIdBase}-cost-${index + 1}`}>
                              {s.rates?.[0].cost == null
                                ? ''
                                : s.rates[0].cost +
                                  ' ' +
                                  formatRateUnit({
                                    chargeBasis: charge?.chargeBasis,
                                    rate: s.rates[0].cost ?? 0,
                                    flags: {
                                      fbau721ExpandVolumeChargeToIncludeNewAttributesAndChargeBasis,
                                    },
                                    tonneUnit: tonneUnit,
                                    currencyUnit: currencyUnit,
                                  })}
                            </td>
                          )}
                          <td data-testid={`${testIdBase}-valid-from-${index + 1}`}>
                            {formatDateForDisplay(s.validFrom)}
                          </td>
                          <td data-testid={`${testIdBase}-valid-to-${index + 1}`}>
                            {formatDateForDisplay(s?.validTo || '')}
                          </td>
                          <td data-testid={`${testIdBase}-rate-qualifier-${index + 1}`}>
                            {s?.rates?.[0]
                              ? s.rates[0].rateQualifiers?.map((q, qualifierIndex) => (
                                  <Link
                                    className="apl-pr-s apl-mr-xs apl-mb-xs apl-color-primary"
                                    to={`/qualifiers/${q.qualifierId}`}
                                    key={qualifierIndex}
                                  >
                                    {q.name}
                                  </Link>
                                ))
                              : ''}
                          </td>
                          {hasApplicableTimePeriod && (
                            <td data-testid={`${testIdBase}-applicable-time-period-${index + 1}`}>
                              {s?.rates?.[0]
                                ? s.rates[0].applicableTimePeriods?.map((a, qualifierIndex) => (
                                    <Link
                                      className="apl-pr-s apl-mr-xs apl-mb-xs"
                                      to={`/qualifiers/${a.qualifierId}`}
                                      key={qualifierIndex}
                                    >
                                      {a.name}
                                    </Link>
                                  ))
                                : ''}
                            </td>
                          )}
                          <td data-testid={`${testIdBase}-bill-group-${index + 1}`}>
                            {s.rates?.[0].summarisationGroup}
                          </td>
                          {fbau721ExpandVolumeChargeToIncludeNewAttributesAndChargeBasis &&
                            chargeRequiresVolumeAdjustmentFactor(
                              charge.chargeType,
                              chargeTypesConfig
                            ) && (
                              <td data-testid={`${testIdBase}-rate-${index + 1}`}>
                                {s.rates?.[0].volumeAdjustmentFactor}
                              </td>
                            )}
                          {fbau689SpotPercentageOnVolumeEnergyCharge &&
                            chargeRequiresSpotPercentage(
                              charge.chargeType,
                              charge.chargeTags?.[0],
                              chargeTypesConfig
                            ) && (
                              <td data-testid={`${testIdBase}-rate-${index + 1}`}>
                                {s.rates?.[0].spotPercentage}
                                {'%'}
                              </td>
                            )}
                        </tr>
                      );
                    })
                : []}
            </tbody>
          </table>
        )}
      </ColumnCard>
      <Modal
        isOpen={editChargeIsOpen}
        onRequestClose={closeEditCharge}
        shouldCloseOnOverlayClick={false}
        style={chargeModalStyles}
      >
        <EditSimpleCharge charge={charge} close={closeEditCharge} plan={plan} provider={provider} />
      </Modal>
    </div>
  );
};

const processChargeDetails = (
  charge: Charge,
  chargeTypesConfig: ChargeTypesConfig,
  sumFunctionOptions: SummarisationFunctionOptions,
  getLossesText: (charge: Charge) => string,
  getHistoricalMeasureText: (historicalMeasure: string | undefined) => string,
  flags: any
): LabelValue[] => {
  const { showDiscountVariations } = getConfig();
  const { data: discountVariationData } = useDiscountVariations(
    `urn:flux:rating:charge:identifier:${charge.id}`,
    {
      enabled: !!charge && !!charge.id && !!showDiscountVariations,
    }
  );

  let baseCharge: LabelValue[] = [
    {
      label: 'Charge basis',
      value: charge?.chargeBasis || '',
    },
    {
      label: 'Charge name',
      value: charge?.chargeName || '',
    },
    {
      label: 'Provider reference',
      value: charge?.chargeReference || '',
    },
    {
      label: 'Charge calculation',
      value: charge?.chargeCalculation ? capitaliseFirstText(charge.chargeCalculation) : 'Required',
    },
  ];

  if (getFlagValue('fbau-635-billing-category-for-invoice-presentation')) {
    baseCharge = [
      ...baseCharge,
      {
        label: 'Billing category',
        value: capitaliseFirstText(charge?.display?.category || ''),
      },
      {
        label: 'Billing descriptor',
        value: charge?.display?.descriptor || '',
      },
    ];
  } else {
    baseCharge = [
      ...baseCharge,
      {
        label: 'Billing descriptor',
        value: charge?.billingDescriptor || '',
      },
    ];
  }

  if (chargeRequiresFlowDirection(charge.chargeType, chargeTypesConfig)) {
    baseCharge = [
      ...baseCharge,
      {
        label: 'Flow direction',
        value: charge?.flowDirection ? capitaliseFirstText(charge?.flowDirection) : '',
      },
    ];
  }

  if (chargeRequiresLossAdjustment(charge.chargeType, chargeTypesConfig)) {
    const value = getLossesText(charge);

    baseCharge = [
      ...baseCharge,
      {
        label: 'Losses',
        value: value,
      },
    ];
  }

  if (chargeRequiresPowerFactorThreshold(charge.chargeType, chargeTypesConfig)) {
    baseCharge = [
      ...baseCharge,
      {
        label: 'Below power factor threshold',
        value: !isNaN(Number(charge?.powerFactor)) ? Number(charge.powerFactor).toFixed(3) : '',
      },
    ];
  }

  if (chargeRequiresTopNPeriods(charge.chargeType, chargeTypesConfig)) {
    baseCharge = [
      ...baseCharge,
      {
        label: 'Top N periods',
        value: charge?.topPeriods ? `${charge?.topPeriods}` : '',
      },
    ];
  }

  if (chargeRequiresSummarisationFunction(charge.chargeType, chargeTypesConfig)) {
    baseCharge = [
      ...baseCharge,
      {
        label: 'Summarisation function',
        value: getSumFunctionLabelByValue(charge?.sumFunction, sumFunctionOptions),
      },
    ];
  }

  if (chargeRequiresLookbackMonths(charge.chargeType, chargeTypesConfig)) {
    baseCharge = [
      ...baseCharge,
      {
        label: 'Lookback months',
        value: charge?.lookbackMonths ? `${charge?.lookbackMonths}` : '',
      },
    ];
  }

  if (chargeRequiresMinimumChargeable(charge.chargeType, chargeTypesConfig)) {
    baseCharge = [
      ...baseCharge,
      {
        label: 'Minimum chargeable demand',
        value: charge?.minimumChargeable ? `${charge?.minimumChargeable}` : '',
      },
    ];
  }

  if (chargeRequiresMinimumThresholdMethod(charge.chargeType, chargeTypesConfig)) {
    baseCharge = [
      ...baseCharge,
      {
        label: 'Minimum Threshold Method',
        value: charge?.minimumThresholdMethod
          ? `${
              charge?.minimumThresholdMethod == 'ZERO'
                ? 'Bill Zero if below minimum'
                : charge?.minimumThresholdMethod == 'MINIMUM'
                ? 'Bill the Minimum if below minimum'
                : ''
            }`
          : '',
      },
    ];
  }

  if (
    getFlagValue('fbau-802-historical-peak-demand-ui') &&
    chargeRequiresHistoricalMeasure(charge.chargeType, chargeTypesConfig)
  ) {
    baseCharge = [
      ...baseCharge,
      {
        label: 'Demand type',
        value: charge?.historicalMeasure
          ? `${getHistoricalMeasureText(charge?.historicalMeasure)}`
          : '',
      },
    ];
  }

  if (chargeRequiresIntervalSize(charge.chargeType, chargeTypesConfig)) {
    baseCharge = [
      ...baseCharge,
      {
        label: 'Interval size',
        value: charge?.intervalSize ? `${charge?.intervalSize}` : '',
      },
    ];
  }

  if (
    flags?.fbau1079AddUsageChargeFilterToVolumeBasedCharges &&
    chargeRequiresUsageChargeFilter(charge.chargeType, chargeTypesConfig)
  ) {
    baseCharge = [
      ...baseCharge,
      {
        label: 'Usage Charge Filter',
        value: charge?.usageChargeFilter ? `${charge?.usageChargeFilter}` : '',
      },
    ];
  }

  if (chargeRequiresReadingQuality(charge.chargeType, chargeTypesConfig)) {
    baseCharge = [
      ...baseCharge,
      {
        label: 'Reading Quality',
        value: charge?.readingQuality ? `${charge?.readingQuality}` : 'BOTH',
      },
    ];
  }

  if (chargeRequiresIsTaxed(charge.chargeType, chargeTypesConfig)) {
    baseCharge = [
      ...baseCharge,
      {
        label: 'Is Taxed',
        value: charge?.isTaxed ? `${charge?.isTaxed}`.replace('_', ' ') : 'TAXED',
      },
    ];
  }

  if (showDiscountVariations && discountVariationData) {
    const { discountVariations } = discountVariationData as DiscountVariations;

    if (discountVariations && discountVariations.length > 0) {
      // The last info is the charges variations
      baseCharge = [
        ...baseCharge,
        {
          label: 'Discount variations',
          value: toString(discountVariations),
        },
      ];
    }
  }

  return baseCharge;
};

export default ChargeDetails;

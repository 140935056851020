import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isAfter, startOfToday } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UsageChargeFiltersContextProvider } from '../../usage-charge-filters/UsageChargeFiltersContext';
import ExpectedChargeComponents from './ExpectedChargeComponents';
import InvoicesComponent from './InvoicesComponent';
import MissingInvoice from './MissingInvoice';
import PlanLinksComponent from './PlanLinksComponent';
import SupplyAgreementComponent from './SupplyAgreementComponent';
import { getMarketFunction } from '../../../list-data/getMarketFunction';
import {
  MarketMap,
  SupplyPeriodSummary,
  useConnectionDetails,
  useSupplyPeriodDetailsV3,
  useSupplyPeriodSummary,
} from '../connectionsApi';
import { Widget } from '../../dashboard/Dashboard';
import Page, { ErrorPage, LoadingPage, PageHeader } from '../../layout/Page';
import { useStore } from '../../../store';
import { isWithinPeriod } from '../../../util/helper-func';
import MeasurementPointsSummary from '../measurement-points/MeasurementPointsSummary';
import MeteringDataComponent from './MeteringDataComponent';
import { Permission } from '../../../auth/getPermissions';
import AuthorizedAction from '../../common/AuthorizedAction';
import HistoricalPeakDemandComponent from '../../metrics/HistoricalPeakDemandComponent';
import { Invoice, useGetInvoices } from 'components/billing/billingInvoicesApi';
import { useListData } from 'list-data/ListDataContext';
import MeteringDataComponentV2 from './MeteringDataComponentV2';
import EquationLinksSummary from '../equation-link/EquationLinksSummary';
import MeteringDataComponentMerxV2 from './MeteringDataComponentMerxV2';
import ConnectionErrorsWidget from './ConnectionErrorsWidget';
import BillingFrequency from '../billing-frequency/BillingFrequency';
import ConnectionErrorsWidgetApex92 from './ConnectionErrorsWidgetApex92';
import { EuiButton } from '@elastic/eui';
import getConfig from 'config/getConfig';
import RequestNewCharges from '../charges/RequestNewCharges';

/**
 * Displays the connection dashboard which is reachable for example via connections
 * screen (.../connections/<id>). It shows supply periods, plans, activities (=invoices and
 * credit notes) and metering data.
 * @constructor
 */
const ConnectionDashboard = () => {
  const [supplyPeriod, setSupplyPeriod] = useState<SupplyPeriodSummary>();
  const [missingInvoice, setMissingInvoice] = useState(false);
  const [selectedContractedPartyId, setSelectedContractedPartyId] = useState<string>();
  const [marketFunctionFlags] = useListData(['MARKET_FUNCTION']);

  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  let { contractedPartyId } = useParams<{ contractedPartyId: string }>();
  const { supplyPeriodId } = useParams<{ supplyPeriodId: string | undefined }>();

  const {
    fbau802HistoricalPeakDemandUi, // LD Client Key is fbau-802-historical-peak-demand-ui
    fbau1150UiViewMeasurementPoints,
    cfd561EquationLinkUi,
    enableBillingFrequencySelector, // LD Client Key is enable-billing-frequency-selector
    apex92DisplayCorrectNmiOnErrorsDashboard, // LD Client Key is apex-92-display-correct-nmi-on-errors-dashboard
  } = useFlags();

  const {
    data: connectionData,
    isError: connectionDataError,
    isInitialLoading: connectionDataLoading,
  } = useConnectionDetails(id, { enabled: !!id });

  const {
    data: supplyPeriodData,
    isError: supplyPeriodError,
    isInitialLoading: supplyPeriodLoading,
  } = useSupplyPeriodSummary(id, { enabled: !!id });

  const { data: supplyPeriodV3 } = useSupplyPeriodDetailsV3(supplyPeriodId, {
    enabled: !!supplyPeriodId,
  });

  // collect all externalSupplyAgreementIds for cancelled supply periods
  const externalSupplyAgreementIds: string[] = supplyPeriodData?.supplyPeriodSummaries
    .filter(
      (period: SupplyPeriodSummary) =>
        period.externalSupplyAgreementId && period.status === 'CANCELLED'
    )
    .map((period: SupplyPeriodSummary) => period.externalSupplyAgreementId);
  // get invoices for all the cancelled supply periods
  const {
    data: invoiceData,
    isError: invoiceDataError,
    isInitialLoading: invoiceDataLoading,
  } = useGetInvoices(
    {
      externalSupplyAgreementIds: externalSupplyAgreementIds,
      limit: 100000,
    },
    {
      enabled: !!externalSupplyAgreementIds?.length,
    }
  );

  let supplyPeriods: SupplyPeriodSummary[] = supplyPeriodData?.supplyPeriodSummaries;

  // filter out cancelled supply periods without any invoices
  if (supplyPeriods && invoiceData) {
    supplyPeriods = supplyPeriods.filter(
      (supplyPeriod: SupplyPeriodSummary) =>
        // include active supply periods
        supplyPeriod.status === 'VALID' ||
        // otherwise if supply period is cancelled, include it if there is an invoice
        invoiceData.items.find(
          (invoice: Invoice) =>
            invoice.data?.externalSupplyAgreementId === supplyPeriod.externalSupplyAgreementId
        )
    );
  }

  // set the url parameters for the View Charge Sets button
  const viewChargeSetParams = new URLSearchParams();
  if (supplyPeriod) {
    viewChargeSetParams.set('external_supply_agreement_id', supplyPeriod.externalSupplyAgreementId);
    viewChargeSetParams.set('contracted_party', supplyPeriod.owner);
  }

  // This is for back navigation. React-router v6 has a useNavigate but doing it this way has the
  // benefit of working properly if deep linking and the breadcrumbs are used.
  if (supplyPeriod?.id) {
    viewChargeSetParams.set('spid', supplyPeriod.id);
  }

  if (supplyPeriod?.startDate) {
    viewChargeSetParams.set('from', supplyPeriod.startDate);
  }

  if (supplyPeriod?.endDate) {
    viewChargeSetParams.set('to', supplyPeriod.endDate);
  }

  const updateUrl = useCallback(
    (supplyPeriod: SupplyPeriodSummary | undefined) => {
      if (supplyPeriod) {
        if (selectedContractedPartyId) {
          navigate(
            `/connections/${id}/supply-periods/${supplyPeriod.id}/contracted-parties/${selectedContractedPartyId}`,
            { replace: true }
          );
        } else {
          navigate(
            `/connections/${id}/supply-periods/${supplyPeriod.id}/contracted-parties/${contractedPartyId}`,
            { replace: true }
          );
        }
      }
    },
    [id, supplyPeriod, selectedContractedPartyId, contractedPartyId]
  );

  useEffect(() => {
    if (contractedPartyId) {
      setSelectedContractedPartyId(contractedPartyId);
    }
  }, [contractedPartyId]);

  useEffect(() => {
    if (supplyPeriods?.length) {
      let supplyPeriodsByContractedParty: SupplyPeriodSummary[] = supplyPeriods;
      if (selectedContractedPartyId) {
        // remove supply periods that don't belong to the selected contracted party ==//
        supplyPeriodsByContractedParty = supplyPeriods.filter(
          (supplyPeriod: SupplyPeriodSummary) => supplyPeriod.owner === selectedContractedPartyId
        );
      }

      // use provided supply period id if available
      let previousPeriod;
      if (supplyPeriodId) {
        // try to find supply period with that id and use it. Assuming user was here before.
        previousPeriod = supplyPeriodsByContractedParty.find(
          (supplyPeriod: SupplyPeriodSummary) => supplyPeriod.id === supplyPeriodId
        );
        if (previousPeriod) {
          setSupplyPeriod(previousPeriod);
        }
      }
      if (!previousPeriod) {
        // try to find the current supply period within the list
        let displayPeriod = supplyPeriodsByContractedParty.find(
          (supplyPeriod: SupplyPeriodSummary) => {
            const startDate = supplyPeriod.startDate ? new Date(supplyPeriod.startDate) : null;
            const endDate = supplyPeriod.endDate ? new Date(supplyPeriod.endDate) : null;
            // set the time to start of day (midnight) to make sure that only the dates will be compared
            startDate?.setHours(0, 0, 0, 0);
            endDate?.setHours(0, 0, 0, 0);
            return isWithinPeriod(startOfToday(), startDate, endDate);
          }
        );

        // pick the latest supply period in case there is no current available
        if (!displayPeriod && supplyPeriodsByContractedParty.length > 0) {
          displayPeriod = getLatestSupplyPeriod(supplyPeriodsByContractedParty);
        }
        setSupplyPeriod(displayPeriod);

        if (!supplyPeriodId) {
          updateUrl(displayPeriod);
        }
      }
    } else {
      // reset if there are no supply periods available
      setSupplyPeriod(undefined);
    }
  }, [
    supplyPeriods,
    supplyPeriodData?.id,
    selectedContractedPartyId,
    supplyPeriodData?.expectedChargeTags,
    supplyPeriodId,
  ]);

  // invoice error should not prevent entire dashboard from rendering
  if (invoiceDataError) {
    console.error(new Error(invoiceData));
  }

  if (connectionDataError || supplyPeriodError) {
    return <ErrorPage />;
  }

  if (connectionDataLoading || supplyPeriodLoading || invoiceDataLoading) {
    return <LoadingPage />;
  }

  const getMarket = (): string | undefined | boolean => {
    return getMarketFunction('urn:flux:rating-config:market', undefined, marketFunctionFlags);
  };

  const displayHistoricalPeakDemand = (): boolean => {
    return MarketMap.AU_MARKET === getMarket();
  };
  const {
    cfd212UsageV2Apis, //cfd-212-usage-v2-apis
    cfd838UsageV2ApisForMerx, // cfd-838-usage-v2-apis-for-merx
  } = useFlags();
  const config = getConfig();
  return (
    <>
      <UsageChargeFiltersContextProvider>
        <PageHeader
          title={connectionData && connectionData[0] && connectionData[0].connectionId}
          children={
            <EuiButton
              href={
                config.coreUrl +
                '/search?q=' +
                (connectionData && connectionData[0] && connectionData[0].connectionId) +
                '&search-submit=search&cancelled_inbounds=1'
              }
            >
              View Connection In Core
            </EuiButton>
          }
        />
        <Page>
          <SupplyAgreementComponent
            connectionId={id}
            currentPeriod={supplyPeriod}
            onPeriodChange={(periodId) => {
              const match = supplyPeriods.find((period) => period.id === periodId);
              setSupplyPeriod(match);
              updateUrl(match);
            }}
            onContractedPartyChange={(selectedContractedPartyId) => {
              setSelectedContractedPartyId(selectedContractedPartyId);
              contractedPartyId = selectedContractedPartyId;
              navigate(`/connections/${id}/contracted-parties/${selectedContractedPartyId}`);
            }}
            onStatusUpdate={useStore.getState().addNotification}
            supplyPeriods={supplyPeriods}
          />
          <div className="apl-display-flex apl-flex-row apl-align-items-start">
            <div className="apl-display-flex apl-flex-column apl-flex-grow-1">
              {missingInvoice && <MissingInvoice period={supplyPeriod} />}
              <Widget
                className="widget--full"
                width="full"
                headerComponent={
                  <h3 className="apl-h3 widget__title widget__title--inline apl-mb-none">
                    Activity
                    {
                      <div style={{ display: 'flex', gap: '10px' }}>
                        {supplyPeriod && (
                          <RequestNewCharges supplyPeriod={supplyPeriod} id={id ?? ''} />
                        )}
                        <AuthorizedAction
                          testId="view-charge-sets-button"
                          onClick={() =>
                            navigate(`/connections/${id}/charges?${viewChargeSetParams.toString()}`)
                          }
                          permission={Permission.CONNECTION_VIEW}
                        >
                          View charge sets
                        </AuthorizedAction>
                      </div>
                    }
                  </h3>
                }
              >
                {connectionData && supplyPeriod && (
                  <InvoicesComponent
                    connection={connectionData[0]}
                    onInvoiceUpdate={setMissingInvoice}
                    period={supplyPeriod}
                  />
                )}
              </Widget>
              {supplyPeriod &&
                (cfd212UsageV2Apis ? (
                  <MeteringDataComponentV2 supplyPeriod={supplyPeriod} />
                ) : cfd838UsageV2ApisForMerx ? (
                  <MeteringDataComponentMerxV2 supplyPeriod={supplyPeriod} />
                ) : (
                  <MeteringDataComponent supplyPeriod={supplyPeriod} />
                ))}
              {fbau802HistoricalPeakDemandUi && displayHistoricalPeakDemand() && (
                <HistoricalPeakDemandComponent
                  connectionId={id}
                  contractedPartyId={contractedPartyId}
                />
              )}
              {apex92DisplayCorrectNmiOnErrorsDashboard ? (
                <ConnectionErrorsWidgetApex92 connectionId={connectionData[0].id} />
              ) : (
                <ConnectionErrorsWidget connectionId={connectionData[0].id} />
              )}
            </div>
            <div className="apl-display-flex apl-flex-column">
              <PlanLinksComponent
                connection={connectionData[0]}
                supplyPeriod={supplyPeriod}
                contractedPartyId={contractedPartyId}
              />
              <ExpectedChargeComponents
                connection={connectionData[0]}
                supplyPeriod={supplyPeriod}
                supplyPeriodV3={supplyPeriodV3}
              />
              {fbau1150UiViewMeasurementPoints && (
                <MeasurementPointsSummary connection={connectionData[0]} />
              )}

              {cfd561EquationLinkUi && <EquationLinksSummary connection={connectionData[0]} />}
              {enableBillingFrequencySelector && (
                <BillingFrequency
                  externalSupplyAgreementId={getExternalSupplyAgreementId(connectionData)}
                />
              )}
            </div>
          </div>
        </Page>
      </UsageChargeFiltersContextProvider>
    </>
  );
};

const getExternalSupplyAgreementId = (connectionData: Record<string, any>) => {
  return connectionData && connectionData.length > 0
    ? connectionData?.[0].supplyPeriods?.[0]?.externalSupplyAgreementId
    : '';
};

/**
 * Returns the latest supplyPeriod from the given array of supplyPeriods.
 * @param supplyPeriods
 */
const getLatestSupplyPeriod = (supplyPeriods: SupplyPeriodSummary[]) => {
  return supplyPeriods.reduce((accum: SupplyPeriodSummary, curr: SupplyPeriodSummary) => {
    const isCurrentEndDateAfter = isDateAfter(
      curr.endDate ? new Date(curr.endDate) : null,
      accum.endDate ? new Date(accum.endDate) : null
    );
    if (isCurrentEndDateAfter) {
      return curr;
    }

    const isCurrentStartDateAfter = isDateAfter(
      curr.startDate ? new Date(curr.startDate) : null,
      accum.startDate ? new Date(accum.startDate) : null
    );
    if (isCurrentStartDateAfter) {
      return curr;
    }
    return accum;
  });
};

/**
 * is dateA after dateB
 * will return 'false' if both are equal as dateA is not "after" dateB
 * Struggling to move this into the utility file as no dateA but having a dateB returns true.
 */
const isDateAfter = (dateA: Date | null | undefined, dateB: Date | null | undefined) => {
  if (!dateB && dateA) {
    return false;
  }

  if (!dateA && dateB) {
    return true;
  }

  if (!dateA && !dateB) {
    return false;
  }

  return isAfter(dateA as Date, dateB as Date);
};

export default ConnectionDashboard;

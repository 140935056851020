import React, { useState } from 'react';
import Modal from 'react-modal';
import { Permission } from 'auth/getPermissions';
import AuthorizedAction from 'components/common/AuthorizedAction';
import { MeasurementPoint as MeasurementPointType } from 'api/openapi/telemetry';
import ContextMenuButton, {
  ContextMenuButtonRenderProps,
} from 'components/common/ContextMenuButton';
import EditMeasurementPoint from './EditMeasurementPoint';
import ViewMeasurementPoint from './ViewMeasurementPoint';
import { modalStyles } from '../../layout/Modal';
import { Link } from 'react-router-dom';
import { buildMeterMeasurementPointIdentifier } from './measurementPointHelpers';
import moment from 'moment/moment';
import getConfig from '../../../config/getConfig';
import { EuiIcon } from '@elastic/eui';

interface MeasurementPointProps {
  id: string;
  measurementPoint: MeasurementPointType;
  connectionId: string;
}

const DATE_TIME_FORMAT = 'DD MMM yyyy HH:mm';

const getDisabledEditProps = (measurementPoint: MeasurementPointType, featureFlag: boolean) => {
  const isSystemCreated = measurementPoint?.isSystemCreated;
  const isMeter = featureFlag && !!measurementPoint?.externalIdentifier;

  if (isSystemCreated || isMeter) {
    return {
      isDisabled: true,
      title: isSystemCreated
        ? 'This measurement point was system generated and cannot be edited'
        : 'Meter measurement point cannot be edited',
    };
  }

  return {};
};

function MeasurementPoint({ measurementPoint, connectionId, id }: MeasurementPointProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [editItem, setEditItem] = useState<MeasurementPointType>();
  const [viewItem, setViewItem] = useState<MeasurementPointType>();
  const config = getConfig();
  const cfd490OffMarketMpUi = config.offMarketMeasurementPoints;

  const euiIcon = (
    <EuiIcon
      size={'xl'}
      style={{ padding: '5px' }}
      type="iInCircle"
      title={
        'Created: ' +
        moment(measurementPoint.createdAt).tz(getConfig().marketTimezone).format(DATE_TIME_FORMAT)
      }
    />
  );

  const measurementPointElement = measurementPoint.externalIdentifier ? (
    // Meter measurement point
    <span>
      {euiIcon}
      {cfd490OffMarketMpUi ? (
        <Link
          to={``}
          className="widget__list-link"
          onClick={() => {
            setViewItem(measurementPoint);
            setModalOpen(true);
          }}
        >
          Meter: {buildMeterMeasurementPointIdentifier(measurementPoint.externalIdentifier)}
        </Link>
      ) : (
        <>Meter: {buildMeterMeasurementPointIdentifier(measurementPoint.externalIdentifier)} </>
      )}
      <span className="badge apl-ml-s">{measurementPoint.usageChargeFilterName}</span>
    </span>
  ) : (
    // Calculated measurement point
    <span>
      {euiIcon}
      {cfd490OffMarketMpUi ? (
        <Link
          to={``}
          className="widget__list-link"
          onClick={() => {
            setEditItem(measurementPoint);
            setModalOpen(true);
          }}
        >
          Calculated: {measurementPoint.description}
        </Link>
      ) : (
        <>Calculated: {measurementPoint.description} </>
      )}
      <span className="badge apl-ml-s">{measurementPoint.usageChargeFilterName}</span>
    </span>
  );

  const closeModal = () => {
    setModalOpen(false);
    setEditItem(undefined);
    setViewItem(undefined);
  };

  return (
    <>
      {measurementPointElement}
      {!cfd490OffMarketMpUi && (
        <ContextMenuButton align="right" testIdSuffix={`${measurementPoint.id}`} width="180px">
          {({ closeMenu }: ContextMenuButtonRenderProps) => {
            return (
              <div className="context-menu__block">
                <AuthorizedAction
                  extraClasses="context-menu__button"
                  testId={`context-menu__button--${measurementPoint.id}`}
                  onClick={() => {
                    closeMenu();
                    setEditItem(measurementPoint);
                    setModalOpen(true);
                  }}
                  permission={Permission.METRIC_EDIT}
                  removeDefaultClass={true}
                  removeDefaultWeight={true}
                  {...getDisabledEditProps(measurementPoint, cfd490OffMarketMpUi)}
                >
                  Edit
                </AuthorizedAction>
              </div>
            );
          }}
        </ContextMenuButton>
      )}

      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={modalStyles}
      >
        {editItem && (
          <EditMeasurementPoint
            id={id}
            connectionId={connectionId}
            measurementPoint={editItem}
            close={closeModal}
          />
        )}
        {viewItem && (
          <ViewMeasurementPoint
            id={id}
            connectionId={connectionId}
            measurementPoint={viewItem}
            close={closeModal}
          />
        )}
      </Modal>
    </>
  );
}

export default MeasurementPoint;
